//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import Swiper from 'swiper/swiper-bundle.min.js';
const MicroModal = require('micromodal');
import smoothscroll from 'smoothscroll-polyfill';
import { listen, prefetch } from 'quicklink';
// global.jQuery = global.$ = window.jQuery = require('jquery');

// quicklink
listen({
  origins: [
    's-echoes.jp',
    's-echoes.com'
  ],
  ignores: [
    /\/api\/?/,
    /#(.+)/,
    /tel:(.+)/,
    /mailto:(.+)/,
    uri => uri.includes('.zip'),
    (uri, elem) => elem.hasAttribute('noprefetch')
  ]
});

// smooth scroll behavior Polyfill
smoothscroll.polyfill();

// 透過ヘッダーかどうか
const isHeaderTransparent = (document.querySelector('.l-header--transparent') !== null) ? true : false;

// グローバルナビ
const gnav = document.getElementById('panel-gnav');
const gnavSub = document.querySelectorAll('.p-gnav__panel');
const gnavItem = document.querySelectorAll('.p-gnav__item:has(.p-gnav__panel)');
const gnavCtrl = document.querySelectorAll('.p-gnav__ctrl');

// ハンバーガーメニュー
const burger = document.getElementById('burger');

if(burger !== null){
  burger.addEventListener('click', (e) => {
    if (burger.getAttribute('aria-expanded') == 'false') {
      burger.setAttribute('aria-expanded', true);
      burger.setAttribute('aria-label', 'メニューを閉じる');
      gnav.setAttribute('aria-hidden', false);
      document.documentElement.classList.add('fixed');
    } else {
      burger.setAttribute('aria-expanded', false);
      burger.setAttribute('aria-label', 'メニューを開く');
      gnav.setAttribute('aria-hidden', true);
      document.documentElement.classList.remove('fixed');
    }
  });
}

// 関連事例カルーセル
const swiperRealatedWrapper = document.querySelector('.js-swiper-related .p-card-carousel__wrapper');
const swiperRelatedSlides = document.querySelectorAll('.js-swiper-related .p-card-carousel__item');
const swiperRelatedCtrls = document.querySelector('.js-swiper-related .p-card-carousel__ctrl');
const swiperRelatedPrevSlideMessage = '前のスライドへ';
const swiperRelatedNextSlideMessage = '次のスライドへ';
const initSwiperRelated = () => {
  const swiperRelated = new Swiper ('.js-swiper-related', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 500,
    grabCursor: true,
    updateOnWindowResize: false,
    pagination: {
      el: '.p-card-carousel__progressbar',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-related .swiper-button-next',
      prevEl: '.js-swiper-related .swiper-button-prev',
    },
    a11y: {
      prevSlideMessage: swiperRelatedPrevSlideMessage,
      nextSlideMessage: swiperRelatedNextSlideMessage,
    },
    breakpoints: {
      768: {
        spaceBetween: 32,
      }
    },
  });

  swiperRelated.on('slideChange', ()=> {
    const prevRelatedBtn = document.querySelector('.js-swiper-related .swiper-button-prev');
    if(prevRelatedBtn !== null){
      if(prevRelatedBtn.classList.contains('disabled')){
        prevRelatedBtn.classList.remove('disabled');
      }
    }
  });
}

// 事例詳細カルーセル
const swiperPostWrapper = document.querySelector('.js-swiper-post .p-post-carousel__wrapper');
const swiperPostSlides = document.querySelectorAll('.js-swiper-post .p-post-carousel__item');
const swiperPostCtrls = document.querySelector('.js-swiper-post .p-post-carousel__ctrl');
const swiperPostPrevSlideMessage = '前のスライドへ';
const swiperPostNextSlideMessage = '次のスライドへ';
const initSwiperPost = () => {
  const swiperPost = new Swiper ('.js-swiper-post', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 8,
    grabCursor: true,
    navigation: {
      nextEl: '.js-swiper-post .swiper-button-next',
      prevEl: '.js-swiper-post .swiper-button-prev',
    },
    a11y: {
      prevSlideMessage: swiperPostPrevSlideMessage,
      nextSlideMessage: swiperPostNextSlideMessage,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 16,
      }
    },
  });

  swiperPost.on('slideChange', ()=> {
    const prevPostBtn = document.querySelector('.js-swiper-post .swiper-button-prev');
    if(prevPostBtn !== null){
      if(prevPostBtn.classList.contains('disabled')){
        prevPostBtn.classList.remove('disabled');
      }
    }
  });
}

//media query
// const mediaQuerySm = window.matchMedia('(min-width: 576px)');
const mediaQueryMd = window.matchMedia('(min-width: 768px)');
const mediaQueryLg = window.matchMedia('(min-width: 992px)');
// const mediaQueryXl = window.matchMedia('(min-width: 1200px)');


// const mediaQuerySmListner = (mq) => {
//   if (mq.matches) { // 576px以上

//   } else { // 576px未満

//   }
// };

const mediaQueryMdListner = (mq) => {
  if (mq.matches) { // 768px以上
    // 関連事例カルーセル
    if(swiperRelatedSlides.length > 3) {
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.remove('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'flex';
      }
      initSwiperRelated();
    }else{
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.add('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'none';
      }
      if(swiperRelatedSlides.length > 0){
        swiperRelatedSlides[0].classList.add('swiper-slide-active');
      }
    }

    // 事例カルーセル
    if(swiperPostSlides.length > 4){
      if(swiperPostWrapper !== null){
        swiperPostWrapper.classList.remove('no-slide');
      }
      if(swiperPostCtrls !== null){
        swiperPostCtrls.style.display = 'flex';
      }
      initSwiperPost();
    }else{
      if(swiperPostWrapper !== null){
        swiperPostWrapper.classList.add('no-slide');
      }
      if(swiperPostCtrls !== null){
        swiperPostCtrls.style.display = 'none';
      }
    }
  } else { // 768px未満
    // 関連事例カルーセル
    if(swiperRelatedSlides.length > 1) {
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.remove('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'flex';
      }
      initSwiperRelated();
    }else{
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.add('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'none';
      }
    }

    // 事例カルーセル
    if(swiperPostSlides.length > 1){
      if(swiperPostWrapper !== null){
        swiperPostWrapper.classList.remove('no-slide');
      }
      if(swiperPostCtrls !== null){
        swiperPostCtrls.style.display = 'flex';
      }
      initSwiperPost();
    }else{
      if(swiperPostWrapper !== null){
        swiperPostWrapper.classList.add('no-slide');
      }
      if(swiperPostCtrls !== null){
        swiperPostCtrls.style.display = 'none';
      }
    }
  }
};

const mediaQueryLgListner = (mq) => {
  if (mq.matches) { // 992px以上
    // グローバルナビ
    gnav.setAttribute('aria-hidden', false);
    [].forEach.call(gnavCtrl, function(ctrl) {
      ctrl.setAttribute('aria-expanded', 'false');
    });
    [].forEach.call(gnavSub, function(panel) {
      panel.setAttribute('aria-hidden', 'true');
    });

    [].forEach.call(gnavItem, function(ctrl) {
      ctrl.addEventListener('mouseenter', function(e) {
        for (var i = 0; i < gnavCtrl.length; i++) {
          gnavCtrl[i].setAttribute('aria-expanded', 'false');
        }
        for (var i = 0; i < gnavSub.length; i++) {
          gnavSub[i].setAttribute('aria-hidden', 'true');
        }
        const targetPanel = e.currentTarget.querySelector('.p-gnav__ctrl').getAttribute('aria-controls');
        e.currentTarget.querySelector('.p-gnav__ctrl').setAttribute('aria-expanded', 'true');
        document.getElementById(targetPanel).setAttribute('aria-hidden', 'false');
      });
    });
    [].forEach.call(gnavItem, function(ctrl) {
      ctrl.addEventListener('mouseleave', function(e) {
        for (var i = 0; i < gnavCtrl.length; i++) {
          gnavCtrl[i].setAttribute('aria-expanded', 'false');
        }
        for (var i = 0; i < gnavSub.length; i++) {
          gnavSub[i].setAttribute('aria-hidden', 'true');
        }
      });
    });

  } else { // 992px未満
    // グローバルナビ
    gnav.setAttribute('aria-hidden', true);
    [].forEach.call(gnavCtrl, function(ctrl) {
      ctrl.setAttribute('aria-expanded', 'true');
    });
    [].forEach.call(gnavSub, function(panel) {
      panel.setAttribute('aria-hidden', 'false');
    });
  }
};

// const mediaQueryXlListner = (mq) => {
//   if (mq.matches) { // 1200px以上

//   } else { // 1200px未満

//   }
// };

// mediaQuerySm.addEventListener("change", mediaQuerySmListner);
mediaQueryMd.addEventListener("change", mediaQueryMdListner);
mediaQueryLg.addEventListener("change", mediaQueryLgListner);
// mediaQueryXl.addEventListener("change", mediaQueryXlListner);

// mediaQuerySmListner(mediaQuerySm);
mediaQueryMdListner(mediaQueryMd);
mediaQueryLgListner(mediaQueryLg);
// mediaQueryXlListner(mediaQueryXl);



// 事例詳細ギャラリー
const galleryImgs = document.querySelectorAll(':not(.swiper-slide-duplicate) .js-gallery');
const galleryWrapper = document.querySelector('.p-modal__wrapper');

[].forEach.call(galleryImgs, (img) => {
  const slideSrc = img.getAttribute('data-gallery-image');
  const slideAlt = img.getAttribute('alt');
  const slide = document.createElement('img');
  if(slideSrc){
    slide.src = slideSrc;
    slide.alt = slideAlt;
    slide.width = 730;
    slide.height = 490;
    const slider = document.createElement('div');
    const caption = document.createElement('p');
    slider.classList.add('swiper-slide','p-modal__slide');
    caption.classList.add('p-modal__caption');
    caption.textContent = slideAlt;
    slider.appendChild(slide);
    slider.appendChild(caption);
    galleryWrapper.appendChild(slider);
  }
});

window.swiperGallery;

window.showGallery = () => {
  const trigger = document.querySelectorAll('[data-micromodal-trigger="modal-gallery"]');
  const swiperGalleryObj = document.querySelector('.js-swiper-gallery');
  const swiperGallerySlides = document.querySelector('.js-swiper-gallery');
  [].forEach.call(trigger, (btn, index) => {
    btn.addEventListener('click', (e) => {
      if(swiperGalleryObj !== null) {
        window.swiperGallery = new Swiper ('.js-swiper-gallery', {
          loop: true,
          slidesPerView: 1,
          initialSlide: index,
          spaceBetween: 0,
          navigation: {
            nextEl: ".js-swiper-gallery .swiper-button-next",
            prevEl: ".js-swiper-gallery .swiper-button-prev",
          }
        });
      }
    });
  });
}

window.closeGallery = () => {
  if(window.swiperGallery){
    window.swiperGallery.destroy(true, true);
    window.swiperGallery = null;
  }
}



// アコーディオン
const acdCtrl = document.querySelectorAll('.js-accrodion');
[].forEach.call(acdCtrl, (ctrl) => {
  ctrl.addEventListener('click', (e) => {
    const targetPanel = e.currentTarget.getAttribute('aria-controls');
    const targetElm = document.getElementById(targetPanel);
    const state = e.currentTarget.getAttribute('aria-expanded');
    if(state == 'false'){
      targetElm.style.height = targetElm.scrollHeight + 'px';
      e.currentTarget.setAttribute('aria-expanded', true);
      e.currentTarget.setAttribute('aria-label', '閉じる');
      targetElm.setAttribute('aria-hidden', false);

    }else{
      targetElm.style.height = 0;
      e.currentTarget.setAttribute('aria-expanded', false);
      e.currentTarget.setAttribute('aria-label', '開く');
      targetElm.setAttribute('aria-hidden', true);
    }
  });
});

// メール・SNSシェア用にタイトルとURLを取得
const pageTitle = document.title;
const pageUrl = location.href;

//各SNSの共有URLを設定
const xUrl = encodeURI('https://twitter.com/share?url=' + pageUrl + '&text=' + pageTitle);
const facebookUrl = encodeURI('https://www.facebook.com/share.php?u=' + pageUrl);

// SNSシェアポップアップ
const winOpen = (url) => {
  const newWin = window.open(url, 'sns-share', 'width=640, height=480, top=0, left=0, personalbar=0, toolbar=0, scrollbars=1, resizable=1');
  if(!newWin){
    location.href = url;
  }
};

// X (Twitter)
const xBtn = document.querySelectorAll('.js-sns-x');
[].forEach.call(xBtn, (btn) =>  {
  btn.addEventListener('click', (e) => {
    winOpen(xUrl);
  });
});

// Facebook
const fbBtn = document.querySelectorAll('.js-sns-facebook');
[].forEach.call(fbBtn, (btn) =>  {
  btn.addEventListener('click', (e) => {
    winOpen(facebookUrl);
  });
});

// 目次生成
const TOC_WRAPPER_SELECTOR = '.p-toc';
const TOC_INSERT_SELECTOR = '#toc';
const HEADING_SELECTOR = '.wp-body>h2';
const TOC_CTRL_BUTTON = '.p-toc__btn';
const TOC_CTRL_WRAPPER_SELECTOR = '.p-toc__toggle';
const PARENT_CLASS_NAME = 'p-toc__list';
const CHILD_CLASS_NAME = 'p-toc__children';
const LINK_CLASS_NAME = '';
const ID_NAME = 'heading';
const tocWrapperElement = document.querySelector(TOC_WRAPPER_SELECTOR);
const tocInsertElement = document.querySelector(TOC_INSERT_SELECTOR);
const headingElements  = document.querySelectorAll(HEADING_SELECTOR);
const tocBtn = document.querySelector(TOC_CTRL_BUTTON);
const tocBtnWrapperElement = document.querySelector(TOC_CTRL_WRAPPER_SELECTOR);
const layer = [];
let id = 0;
const uid = () => {
  return `${ID_NAME}${id++}`;
};
let oldRank = -1;
try {
  const createLink = (el) => {
    let li = document.createElement('li');
    let a  = document.createElement('a');
    el.id  = el.id || uid();
    a.href = `#${el.id}`;
    a.innerText = el.innerText;
    a.className = LINK_CLASS_NAME;
    li.appendChild(a);
    return li;
  };

  const findParentElement = (layer, rank, diff) => {
    do {
      rank += diff;
      if (layer[rank]) return layer[rank];
    } while (0 < rank && rank < 7);
      return false;
  };

  const appendToc = (el, toc) => {
    el.appendChild(toc.cloneNode(true));
  };

  if(headingElements.length !== 0){
    [].forEach.call(headingElements, (el) => {
      let rank   = Number(el.tagName.substring(1));
      let parent = findParentElement(layer, rank, -1);
      if (oldRank > rank) layer.length = rank + 1;
      if (!layer[rank]) {
        layer[rank] = document.createElement('ol');
        if (parent.lastChild) {
          layer[rank].className = CHILD_CLASS_NAME;
          parent.lastChild.appendChild(layer[rank]);
        }else{
          layer[rank].className = PARENT_CLASS_NAME;
        }
      }
      layer[rank].appendChild(createLink(el));
      oldRank = rank;
    });
  }else{
    if(tocWrapperElement !== null){
      tocWrapperElement.style.display = 'none';
    }
  }

  const dispToc = () => {
    if ((headingElements.length > 3)) {
      tocBtn.setAttribute('aria-expanded', 'false');
      tocInsertElement.setAttribute('aria-hidden', 'true');
    } else {
      tocBtnWrapperElement.style.display = "none";
    }
  };

  if(tocBtn !== null) {
    tocBtn.addEventListener('click', (e) => {
      const targetPanel = e.currentTarget.getAttribute('aria-controls');
      const targetElm =  document.getElementById(targetPanel);
      const state = e.currentTarget.getAttribute('aria-expanded');
      if(state == 'false'){
        e.currentTarget.setAttribute('aria-selected', 'true');
        e.currentTarget.setAttribute('aria-expanded', 'true');
        e.currentTarget.innerHTML = '<span>閉じる</span>';
        targetElm.setAttribute('aria-hidden', 'false');
      }else{
        e.currentTarget.setAttribute('aria-selected', 'false');
        e.currentTarget.setAttribute('aria-expanded', 'false');
        e.currentTarget.innerHTML = '<span>もっと見る</span>';
        targetElm.setAttribute('aria-hidden', 'true');
      }
    });
  }

  if (layer.length) {
    appendToc(tocInsertElement, findParentElement(layer, 0, 1));
    dispToc();
  }
} catch (e) {
  //error
}


// スクロール量取得
const getScrolled = () => {
  return (window.pageYOffset !== undefined) ? window.pageYOffset: document.documentElement.scrollTop;
}

// ページトップボタン
const pagetopBtn = document.querySelector('#pagetop');

// ページトップへスクロール
const scrollToTop = () => {
  pagetopBtn.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
};
scrollToTop();

// ページ内リンクスムーススクロール（指定IDへスクロール）
const scrollToId = () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  [].forEach.call(anchorLinks, (link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      // 固定ヘッダーなどの固定要素がある場合はその分の高さを考慮
      // 固定要素のセレクタはコードに合わせて適宜変更
      const headerHeight = document.querySelector('header') !== null ? document.querySelector('header').clientHeight : 0;
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = getScrolled() + targetElement.getBoundingClientRect().top - headerHeight;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth"
      });
    });
  });
};
scrollToId();

// ハッシュ（ID）付きURLでロードされた場合
const loadScrollToId = () => {
  const targetId = location.hash;
  if(!targetId){
    return;
  }
  const targetElement = document.querySelector(targetId);
  if(targetElement === null){
    return;
  }
  const headerHeight = document.querySelector('header') !== null ? document.querySelector('header').clientHeight : 0;
  const targetOffsetTop = getScrolled() + targetElement.getBoundingClientRect().top - headerHeight;
  window.scrollTo({
    top: targetOffsetTop,
    behavior: "smooth"
  });
};

// 電話番号リンクをスマホ以外で無効化
const ua = navigator.userAgent.toLowerCase();
const isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
if (!isMobile) {
  const telLinks = document.querySelectorAll('a[href^="tel:"]');
  [].forEach.call(telLinks, (link) => {
    link.style.pointerEvents = "none";
    link.addEventListener('click', (e) => {
      e.preventDefault();
    });
  });
}

// Dom読み込み時実行
// document.addEventListener('DOMContentLoaded', () => {

// });

// コンテンツ読み込み完了後に実行
window.addEventListener('load', () => {
  // ハッシュ付きURLの場合の位置変更
  loadScrollToId();

  //フェードインアニメーション
  const animElement = document.querySelectorAll('.js-effect-fadein');
  const animElementArr = Array.prototype.slice.call(animElement);

  const fadeinObserver = (entries, observer) => {
    [].forEach.call(entries, (entry) => {
      if(entry.isIntersecting) {
        entry.target.classList.add('inview');
        observer.unobserve(entry.target);
      }
    });
  }
  const options = {
    rootMargin: "-100px 0px"
  }
  const io = new IntersectionObserver(fadeinObserver, options);
  io.POLL_INTERVAL = 100;
  [].forEach.call(animElementArr, (el) => {
    io.observe(el);
  });
});

// スクロール時実行
window.addEventListener("scroll", () => {
  // 透過ヘッダーの場合、スクロール位置で色変更
  const header = document.querySelector('.l-header');
  if(isHeaderTransparent){
    (getScrolled() > 100) ? header.classList.remove('l-header--transparent') : header.classList.add('l-header--transparent');
  }
});
